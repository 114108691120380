import React from "react";

import * as S from "./hero.styles";

const HeroSimple = ({ title, intro, hasBorder, className }) => (
  <div
    css={[
      S.container,
      S.txtCenter,
      S.flexColCenterAll,
      S.heroRoot,
      hasBorder && S.borderBot,
    ]}
    className={className}
    id="page-header"
  >
    <S.Title as="h1">{title}</S.Title>
    {intro && <p>{intro}</p>}
  </div>
);

HeroSimple.defaultProps = {
  hasBorder: true,
};

export default HeroSimple;
