import React from "react";
import { graphql } from "gatsby";

import * as S from "./faq-list.styles";

import AnchorLink from "../components/anchor-link";
import MarkdownBlock from "../components/markdown-block";

const kebab = (txt) =>
  encodeURI(
    txt
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/\s+/g, "-")
      .toLowerCase()
  );

const FAQItem = ({ question, answer }) => (
  <S.FAQWrap>
    <AnchorLink name={`${kebab(question)}`} />
    <S.FAQTitle>
      <a href={`#${kebab(question)}`}>{question}</a>
    </S.FAQTitle>
    <S.FAQText>
      <MarkdownBlock content={answer} />
    </S.FAQText>
  </S.FAQWrap>
);

const FAQList = ({ list }) => {
  if (list.length < 1) {
    return <></>;
  }

  return (
    <div css={[S.container, S.wrap]}>
      {list.map((q, idx) => (
        <FAQItem key={idx} {...q} />
      ))}
    </div>
  );
};

FAQList.defaultProps = {
  list: [],
};

export const faqListFields = {
  label: "FAQs",
  name: "faqlist",
  widget: "list",
  fields: [
    {
      label: "Question",
      name: "question",
      widget: "string",
    },
    {
      label: "Answer",
      name: "answer",
      widget: "markdown",
    },
  ],
};

export const FAQListFragment = graphql`
  fragment FAQList on ContentJsonFaqlist {
    question
    answer
  }
`;

export default FAQList;
