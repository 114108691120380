import React from "react";
import _get from "lodash.get";
import { useStaticQuery, graphql } from "gatsby";

import FAQPageTemplate from "../templates/FAQPage";

const FAQPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson(templateId: { eq: "faq" }) {
        ...FAQPage
      }
    }
  `);

  return <FAQPageTemplate {..._get(data, "contentJson", {})} />;
};

export default FAQPage;
