import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { rem, fontSize, fontWeights } from "../styles/utils";
import { grayDark, red } from "../styles/colors";

export { container } from "../styles/shared";

export const wrap = css`
  padding-top: ${rem(80)};
  padding-bottom: ${rem(80)};
`;

export const FAQWrap = styled.div`
  margin-bottom: ${rem(54)};
`;

export const FAQTitle = styled.h2`
  ${fontSize(26, 39)};
  font-weight: ${fontWeights.bold};
  margin-top: 0;
  margin-bottom: ${rem(13)};

  a {
    position: relative;
    color: inherit;
    text-decoration: none;
  }

  a:hover::before {
    content: "#";
    position: absolute;
    left: ${rem(-20)};
    top: -0.2em;
    opacity: 0.75;
  }
`;

export const FAQText = styled.div`
  ${fontSize(18, 26)};
  color: ${grayDark};
  font-weight: ${fontWeights.light};

  p {
    margin: 0;
  }

  p ~ p {
    margin-top: 1em;
  }

  a {
    display: inline-block;
    color: ${red};
    text-decoration: none;
    border-bottom: ${rem(1)} solid ${red};

    &:hover,
    &:active,
    &:focus {
      color: ${red};
      border-color: transparent;
    }
  }
`;
