import React, { memo } from "react";

import * as S from "./anchor-link.styles";

const AnchorLink = React.forwardRef((props, ref) => (
  <S.ALRoot>
    <S.ALTarget
      as="a"
      {...props}
      ref={ref}
      className="ring-blue-800"
      tabIndex="-1"
    />
  </S.ALRoot>
));

export default memo(AnchorLink);
