import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import Layout from "../components/layout";
import SEOBlock, { seoFields } from "../components/seo";
import HeroSimple from "../components/hero-simple";
import FAQList, { faqListFields } from "../components/faq-list";

import * as S from "../styles/shared";

const FAQPage = (data) => (
  <Layout css={[S.containerNarrow, S.bgOffWhite]}>
    <SEOBlock {..._get(data, "seo")} titleTemplate="%s | TED@Work" />
    <HeroSimple title={_get(data, "title")} />
    <FAQList list={_get(data, "faqlist")} />
  </Layout>
);

export const faqPageFields = [
  seoFields,
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  faqListFields,
];

export const FAQPageFragment = graphql`
  fragment FAQPage on ContentJson {
    title
    seo {
      ...SEO
    }
    faqlist {
      ...FAQList
    }
  }
`;

export default FAQPage;
